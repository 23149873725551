@import '../../colors.css';

#webdavLogin h2 {
  font-size: 3.5em;
  margin: 0;
}

#webdavLogin form {
  display: table;
}
#webdavLogin p {
  display: table-row;
}
#webdavLogin label {
  display: table-cell;
}
#webdavLogin input {
  display: table-cell;
}

.sync-service-sign-in-container {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.sync-service-sign-in__help-text {
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold;

  font-size: 18px;
  color: gray;

  text-align: center;
}

.sync-service-container {
  border-bottom: 1px solid var(--base01);
  cursor: pointer;

  padding: 20px 10px;
}

.sync-service-container:last-of-type {
  border-bottom: none;
}

.dropbox-logo {
  width: 80%;
}

.google-drive-logo {
  width: 100%;
}
